/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Grid,
  MenuItem,
  IconButton,
  Checkbox,
  Button,
  Typography,
  Box,
  InputAdornment,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "./assets/delete-icon.png";
import Divider from "@mui/material/Divider";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IngredientPlaceholder from "./assets/ingredient-placeholder1.png";
import ReorderImage from "./assets/reorder-img.png";
import ModifiedAutoCompleteWithOptionsObject from "./modified-field/modified-auto-complete";
import ModifiedSelect from "./modified-field/modified-select";
import ModifiedTextField from "./modified-field/modified-text-field";
import ModifiedSelectForPod from "./modified-field/modified-pod-select";
import { useGlobalData } from "../../config/global-data-context";
import { useLocation } from "react-router-dom";
import ReorderIngredientsModal from "./components/reorder-ingredients-popup";
import useRecipeIngredients from "./hooks/use-recipe-ingredients";
import { RecipeEntityContext } from "./hooks/entity-context";
import DeleteUsedIngredientPopup from "./components/delete-used-ingredient";

export default function RecipeIngredients(props) {
  const { sectionId, addSectionButton, setAddSectionButton } = props;
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    formState,
  } = useFormContext();
  const {
    ingredients: ingredientsFromDb,
    prepStyles,
    units,
  } = useContext(RecipeEntityContext);
  const getUnitsForIngredient = (ingredientName, isOutput) => {
    if (isOutput) {
      return units.map((item) => item.name);
    }

    const ingredient = ingredientsFromDb.find(
      (ing) => ing.name.toLowerCase() === (ingredientName || "").toLowerCase()
    );

    if (ingredient) {
      const otherUnits = ingredient.otherUnits || [];
      const allUnits = [...otherUnits];
      return allUnits;
    } else {
      return [];
    }
  };
  const isError = errors?.recipeIngredients;
  const location = useLocation();
  const hostNameLocation = window.location.hostname;
  const [editState, setEditState] = useState(false);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [selectIngredientId, setSelectIngredientId] = useState("");
  const [selectedIngredient, setSelectedIngredient] = useState("");
  const [openReorderPopup, setOpenReorderPopup] = useState(false);
  const {
    addRecipeIngredient,
    removeRecipeIngredient,
    selectIngredientHandler,
    ingredientUnits,
    ingredientPrepStyles,
    openDeletePopup,
    setOpenDeletePopup,
    removeTaggedIngredient,
  } = useRecipeIngredients({
    setValue,
    getValues,
    setAddSectionButton,
    setOpenMoreOptions,
    openMoreOptions,
  });
  const recipeSections = getValues("recipeSections")?.filter(
    (section) => section.id === sectionId
  )[0];

  const sectionType = recipeSections?.sectionType;
  const isLoopSection = recipeSections?.isLoopSection;
  const recipeIngredients = getValues("recipeIngredients");

  const filterIngredient = recipeIngredients?.filter(
    (ing) => ing?.sectionId === sectionId
  );

  const handleMoreOption = (ingredient) => {
    if (ingredient?.id === selectIngredientId) {
      setOpenMoreOptions(!openMoreOptions);
    } else {
      setOpenMoreOptions(true);
    }
    setSelectedIngredient(ingredient);
    setSelectIngredientId(ingredient?.id);
  };

  useEffect(() => {
    if (addSectionButton) {
      addRecipeIngredient(sectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  useEffect(() => {
    location.state?.recipeId ? setEditState(true) : setEditState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoopSection) {
      const updatedRecipeIngredients = recipeIngredients.map((item) => {
        if (item.sectionId === sectionId) {
          return { ...item, isLoopIngredient: true };
        }
        return item;
      });
      setValue("recipeIngredients", updatedRecipeIngredients);
    }
  }, [isLoopSection, recipeIngredients?.length]);

  return (
    <Box mt={4} sx={{ width: "100%" }}>
      <DeleteUsedIngredientPopup
        openDeletePopup={openDeletePopup}
        setOpenDeletePopup={setOpenDeletePopup}
        removeTaggedIngredient={removeTaggedIngredient}
        selectedIngredient={selectedIngredient}
        sectionId={sectionId}
        setOpenMoreOptions={setOpenMoreOptions}
      />
      <Box display="flex" mb={2}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Ingredient in section{" "}
          {recipeSections?.sectionIndex + 1 <= 9
            ? `0${recipeSections?.sectionIndex}`
            : recipeSections?.sectionIndex}
        </Typography>
        <Box onClick={() => setEditState(!editState)} ml={1}>
          {editState ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Box>
      </Box>

      {editState ? (
        <Box
          sx={{
            height: "142px",
            padding: "16px",
            background: "#F7F7F7",
            borderRadius: "8px",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                color: "#7C7B79",
                marginBottom: "12px",
                textTransform: "uppercase",
              }}
            >
              Ingredient status
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              width="160px"
              alignItems="center"
            >
              <Box display="flex">
                {" "}
                <DoneIcon
                  sx={{
                    borderRadius: "50%",
                    background: "#6DA83C",
                    color: "#fff",
                    marginRight: "4px",
                    fontSize: "18px",
                  }}
                />
                <Typography variant="body2" sx={{ fontSize: "13px" }}>
                  USED
                </Typography>
              </Box>
              <Box display="flex">
                <RadioButtonUncheckedIcon
                  sx={{ marginRight: "4px", fontSize: "21px" }}
                />
                <Typography variant="body2" sx={{ fontSize: "13px" }}>
                  UNUSED
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            sx={{ width: "100%", height: "100%", overflowX: "scroll" }}
          >
            {filterIngredient?.map((ingredient) => (
              <Box
                mr={2}
                sx={{
                  height: 82,
                  width: 58,
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    height: 48,
                    width: 48,
                    border: ingredient?.instructionId && "4px solid #6DA83C",
                    position: "relative",
                  }}
                >
                  {ingredient?.instructionId && (
                    <DoneIcon
                      sx={{
                        borderRadius: "50%",
                        background: "#6DA83C",
                        color: "#fff",
                        position: "absolute",
                        right: "-9px",
                        fontSize: "20px",
                      }}
                    />
                  )}
                  <img
                    src={ingredient?.ingredientImage || IngredientPlaceholder}
                    onError={(e) => (e.target.src = IngredientPlaceholder)}
                    style={{ height: 48, width: 48, borderRadius: "50%" }}
                    alt=""
                  />
                </Box>
                <Typography variant="body4" mt={1}>
                  {ingredient?.name || "Ingredient Name"}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Grid container width="100%">
          <Grid
            item
            xs={12}
            sx={{
              background: "#F1F1F1",
              height: 50,
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              pl: 1,
            }}
            mb={2}
          >
            <Grid container width="100%">
              <Grid
                item
                sx={{
                  ...styles.tableHeader,
                  width:
                    isLoopSection && sectionType !== "hardware"
                      ? "30%"
                      : sectionType === "manual"
                      ? "40%"
                      : "20%",
                }}
              >
                <Typography variant="h7" sx={{ fontSize: "14px" }}>
                  Name <span style={{ color: "#EF4823" }}> *</span>
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  ...styles.tableHeader,
                  width: sectionType === "manual" ? "20%" : "13%",
                }}
              >
                <Typography variant="h7" sx={{ fontSize: "14px" }}>
                  {isLoopSection ? "Section Qty" : "Quantity"}
                  <span style={{ color: "#EF4823" }}> *</span>
                </Typography>
              </Grid>

              {isLoopSection && sectionType !== "hardware" && (
                <>
                  {" "}
                  <Grid
                    item
                    sx={{
                      ...styles.tableHeader,
                      width: "16%",
                    }}
                  >
                    <Typography variant="h7" sx={{ fontSize: "14px" }}>
                      Step Qty
                      {/* <span style={{ color: "#EF4823" }}> *</span> */}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid
                item
                sx={{
                  ...styles.tableHeader,
                  width:
                    isLoopSection && sectionType !== "hardware"
                      ? "15%"
                      : sectionType === "manual"
                      ? "20%"
                      : "16%",
                }}
              >
                <Typography variant="h7" sx={{ fontSize: "14px", ml: 1 }}>
                  Preparation style
                </Typography>
              </Grid>

              {sectionType === "hardware" &&
                hostNameLocation !== "klynk.recipes" && (
                  <>
                    <Grid
                      item
                      sx={{
                        ...styles.tableHeader,
                        width: "8%",
                      }}
                    >
                      <Typography variant="h7" sx={{ fontSize: "14px" }}>
                        Loading Position
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        ...styles.tableHeader,
                        width: "8%",
                      }}
                    >
                      <Typography variant="h7" sx={{ fontSize: "14px" }}>
                        Riku's Context
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        ...styles.tableHeader,
                        width: "12%",
                      }}
                    >
                      <Typography variant="h7" sx={{ fontSize: "14px" }}>
                        Pod Type
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        ...styles.tableHeader,
                        width: "8%",
                      }}
                    >
                      <Typography variant="h7" sx={{ fontSize: "14px" }}>
                        IngPodPos
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        ...styles.tableHeader,
                        width: "7%",
                      }}
                    >
                      <Typography v variant="h7" sx={{ fontSize: "14px" }}>
                        Exp.(min)
                      </Typography>
                    </Grid>
                  </>
                )}

              {sectionType !== "hardware" && (
                <Grid
                  item
                  sx={{
                    ...styles.tableHeader,
                    width: sectionType === "manual" ? "13%" : "10%",
                  }}
                >
                  <Typography variant="h7" sx={{ fontSize: "14px" }}>
                    Skip Scaling
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                sx={{
                  ...styles.tableHeader,
                  width: "5%",
                }}
              >
                <Typography variant="h7" sx={{ fontSize: "14px" }}>
                  More
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {recipeIngredients &&
            recipeIngredients
              ?.filter((ing) => ing?.sectionId == props?.sectionId)
              ?.map((ingredient, index) => {
                const ingredientIndex = recipeIngredients.findIndex(
                  (ing) => ing.id === ingredient.id
                );
                const ingredientUnits = getUnitsForIngredient(
                  ingredient.name,
                  ingredient.isOutput
                );
                return (
                  <Grid xs={12}>
                    <Grid
                      container
                      spacing={1}
                      width="100%"
                      ml={1}
                      key={ingredient.id}
                      // sx={{
                      //   border:
                      //     isError &&
                      //     isError[ingredientIndex]?.instructionId &&
                      //     "1px solid red",
                      // }}
                    >
                      <Grid
                        item
                        sx={{
                          ...styles.tableHeader,
                          width:
                            isLoopSection && sectionType !== "hardware"
                              ? "30%"
                              : sectionType === "manual"
                              ? "40%"
                              : "20%",
                        }}
                      >
                        {/* {ingredient.name} */}
                        <ModifiedAutoCompleteWithOptionsObject
                          selectIngredientHandler={selectIngredientHandler}
                          isLoopSection={isLoopSection}
                          parentIndex={ingredientIndex}
                          recipeIngredients={recipeIngredients}
                          ingredientsFromDB={ingredientsFromDb}
                          disabledItemsFocusable={ingredient?.instructionId}
                          disabled={
                            ingredient?.instructionId || ingredient.isOutput
                          }
                          size={"small"}
                          options={[
                            ...new Set(
                              ingredientsFromDb.map((item) => item.name)
                            ),
                          ]}
                          control={control}
                          placeholder="Ingredient name"
                          name={`recipeIngredients.${ingredientIndex}.name`}
                          isError={
                            isError && isError[ingredientIndex]?.name?.message
                          }
                          sx={{
                            ".MuiAutocomplete-input": {
                              textTransform: "capitalize",
                            },
                          }}
                          fullWidth={true}
                          InputProps={
                            ingredient?.isOutput && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography
                                    variant="body"
                                    color="#EF4823"
                                    sx={{
                                      background: "#FFECE4",
                                      borderRadius: "10px",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      px: 1,
                                      textAlign: "end",
                                    }}
                                  >
                                    DERIVED
                                  </Typography>
                                </InputAdornment>
                              ),
                            }
                          }
                        />
                        {isError && (
                          <Typography variant="caption1" color="#EF4823">
                            {isError[ingredientIndex]?.name
                              ? isError[ingredientIndex]?.name?.message
                              : isError[ingredientIndex]?.instructionId
                                  ?.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          ...styles.tableHeader,
                          width: sectionType === "manual" ? "8%" : "5%",
                        }}
                      >
                        <ModifiedTextField
                          style={{
                            padding: "4px ",
                            border:
                              isError &&
                              isError[ingredientIndex]?.quantity &&
                              "1px solid red",
                          }}
                          variant={"standard"}
                          placeholder="Amt"
                          fullWidth
                          size="small"
                          type="number"
                          // error={isError[ingredientIndex]?.quantity}
                          inputProps={{ max: 9999 }}
                          control={control}
                          name={`recipeIngredients.${ingredientIndex}.quantity`}
                        />
                        {isError && (
                          <Typography variant="caption1" color="#EF4823">
                            {isError &&
                              isError[ingredientIndex]?.quantity?.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          ...styles.tableHeader,
                          width: sectionType === "manual" ? "12%" : "8%",
                        }}
                      >
                        <ModifiedAutoCompleteWithOptionsObject
                          setValue={setValue}
                          index={ingredientIndex}
                          parentIndex={ingredientIndex}
                          options={ingredientUnits}
                          control={control}
                          error={isError && isError[ingredientIndex]?.units}
                          size={"small"}
                          placeholder="Unit"
                          name={`recipeIngredients.${ingredientIndex}.units`}
                          fullWidth={true}
                          isError={
                            isError && isError[ingredientIndex]?.units?.message
                          }
                          sx={{
                            ".MuiAutocomplete-input": {
                              textTransform: "capitalize",
                            },
                          }}
                        />
                        {isError && (
                          <Typography variant="caption1" color="#EF4823">
                            {isError &&
                              isError[ingredientIndex]?.units?.message}
                          </Typography>
                        )}
                      </Grid>
                      {isLoopSection && sectionType !== "hardware" && (
                        <>
                          <Grid
                            item
                            sx={{ ...styles.tableHeader, width: "6.5%" }}
                          >
                            <ModifiedTextField
                              style={{
                                padding: "4px ",
                                border:
                                  isError &&
                                  isError[ingredientIndex]?.quantityPerStep &&
                                  "1px solid red",
                              }}
                              variant="standard"
                              placeholder="Amt"
                              fullWidth
                              size="small"
                              type="number"
                              inputProps={{ max: 9999 }}
                              control={control}
                              name={`recipeIngredients.${ingredientIndex}.quantityPerStep`}
                              sx={{
                                ".MuiAutocomplete-input": {
                                  textTransform: "capitalize",
                                },
                              }}
                            />
                            {isError && (
                              <Typography variant="caption1" color="#EF4823">
                                {isError &&
                                  isError[ingredientIndex]?.quantityPerStep
                                    ?.message}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            sx={{ ...styles.tableHeader, width: "10%" }}
                          >
                            <ModifiedAutoCompleteWithOptionsObject
                              setValue={setValue}
                              options={ingredientUnits}
                              control={control}
                              size={"small"}
                              name={`recipeIngredients.${ingredientIndex}.unitsPerStep`}
                              fullWidth={true}
                              isError={
                                isError &&
                                isError[ingredientIndex]?.unitsPerStep?.message
                              }
                              sx={{
                                ".MuiAutocomplete-input": {
                                  textTransform: "capitalize",
                                },
                              }}
                            />
                            {isError && (
                              <Typography variant="caption1" color="#EF4823">
                                {isError &&
                                  isError[ingredientIndex]?.unitsPerStep
                                    ?.message}
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}

                      <Grid
                        item
                        sx={{
                          ...styles.tableHeader,
                          width:
                            isLoopSection && sectionType !== "hardware"
                              ? "13%"
                              : sectionType === "manual"
                              ? "19%"
                              : "15%",
                        }}
                      >
                        <ModifiedAutoCompleteWithOptionsObject
                          setValue={setValue}
                          size={"small"}
                          index={ingredientIndex}
                          parentIndex={ingredientIndex}
                          options={
                            ingredientPrepStyles[0]
                              ? ingredientPrepStyles
                              : ingredient?.name
                              ? prepStyles.map((item) => item.name)
                              : []
                          }
                          control={control}
                          placeholder="Prep style"
                          name={`recipeIngredients.${ingredientIndex}.prepStyles`}
                          fullWidth={true}
                        />
                      </Grid>
                      {sectionType === "hardware" &&
                        hostNameLocation !== "klynk.recipes" && (
                          <>
                            <Grid
                              item
                              sx={{ ...styles.tableHeader, width: "8%" }}
                            >
                              <ModifiedSelect
                                // style={{ minWidth: 120 }}
                                size={"small"}
                                control={control}
                                name={`recipeIngredients.${ingredientIndex}.loadingPosition`}
                                label="Derived"
                              >
                                <MenuItem value={""}>N/A</MenuItem>
                                <MenuItem value={"spiceRack"}>
                                  Spice Rack
                                </MenuItem>
                                <MenuItem value={"ingredientRack"}>
                                  Ingredient Rack
                                </MenuItem>
                                <MenuItem value={"liquidRack"}>
                                  Liquid Rack
                                </MenuItem>
                              </ModifiedSelect>
                            </Grid>
                            <Grid
                              item
                              sx={{ ...styles.tableHeader, width: "8%" }}
                            >
                              <ModifiedSelect
                                // style={{ minWidth: 120 }}
                                size={"small"}
                                control={control}
                                name={`recipeIngredients.${ingredientIndex}.categoryInContextOfRiku`}
                                label="Derived"
                              >
                                <MenuItem value="">select</MenuItem>
                                {/* <MenuItem value="oil">Oil</MenuItem>
              <MenuItem value="water">Water</MenuItem> */}
                                <MenuItem value="spiceOrHerb">
                                  Spice/Herb
                                </MenuItem>
                                <MenuItem value="sauce">
                                  Sauce Ingredient
                                </MenuItem>
                                <MenuItem value="flavoring">Flavor</MenuItem>
                                <MenuItem value="main">
                                  Main Ingredient
                                </MenuItem>
                                <MenuItem value="seasoning">Seasoning</MenuItem>
                                <MenuItem value="garnish">Garnish</MenuItem>
                              </ModifiedSelect>
                            </Grid>
                            {/* TODO: Pod Type should be filtered based on the loadingPosition */}
                            <Grid
                              item
                              sx={{ ...styles.tableHeader, width: "12%" }}
                            >
                              <ModifiedSelect
                                // style={{ minWidth: 120 }}
                                size={"small"}
                                control={control}
                                name={`recipeIngredients.${ingredientIndex}.podType`}
                                label="Derived"
                              >
                                <MenuItem value={""}>N/A</MenuItem>
                                <MenuItem value={"powderedSpicePod"}>
                                  Powdered Spices Pod
                                </MenuItem>
                                <MenuItem value={"pelletSpicePod"}>
                                  Pellets Spice Pod
                                </MenuItem>
                                <MenuItem value={"solidIngredientPod"}>
                                  Solid ing. Pod
                                </MenuItem>
                                <MenuItem value={"pasteIngredientPod"}>
                                  Paste ing. Pod
                                </MenuItem>
                                <MenuItem value={"oilPod"}>Oil Pod</MenuItem>
                                <MenuItem value={"waterPod"}>
                                  Water Pod
                                </MenuItem>
                              </ModifiedSelect>
                            </Grid>

                            <Grid
                              item
                              sx={{ ...styles.tableHeader, width: "8%" }}
                            >
                              <ModifiedSelectForPod
                                multiple={true}
                                // style={{ minWidth: 60 }}
                                size={"small"}
                                control={control}
                                name={`recipeIngredients.${ingredientIndex}.podPosition`}
                                label="Derived"
                              >
                                <MenuItem value={""}>N/A</MenuItem>
                                {[1, 2, 3, 4, 5, 6, 7].map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </ModifiedSelectForPod>
                            </Grid>
                            <Grid
                              item
                              sx={{ ...styles.tableHeader, width: "7%" }}
                            >
                              <ModifiedTextField
                                style={{
                                  background: "#fff",
                                  padding: "5px 12px",
                                }}
                                variant="standard"
                                size={"small"}
                                control={control}
                                name={`recipeIngredients.${ingredientIndex}.schedulingLimit`}
                                fullWidth={true}
                              />
                            </Grid>
                          </>
                        )}

                      {sectionType !== "hardware" && (
                        <Grid
                          item
                          sx={{
                            ...styles.tableHeader,
                            width: sectionType === "manual" ? "13%" : "5%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Controller
                            name={`recipeIngredients.${ingredientIndex}.isScaleIngredient`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        sx={{
                          ...styles.tableHeader,
                          width: "5%",
                          position: "relative",
                        }}
                      >
                        {openMoreOptions &&
                          ingredient.id === selectIngredientId && (
                            <Box
                              sx={{
                                padding: "14px 6px",
                                width: "145px",

                                background: "#fff",
                                position: "absolute",
                                top: "35px",
                                right: "20px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
                                zIndex: 10,
                              }}
                            >
                              <Grid
                                display="flex"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOpenReorderPopup(true);
                                  setOpenMoreOptions(false);
                                }}
                              >
                                <img
                                  src={ReorderImage}
                                  alt=""
                                  style={{ width: 20, height: 20 }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "14px" }}
                                  ml={1}
                                >
                                  Reorder Ingredient
                                </Typography>
                              </Grid>
                              <Divider sx={{ marginY: "5px" }} />
                              <Grid
                                display="flex"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  removeRecipeIngredient(ingredient, sectionId)
                                }
                              >
                                <img src={DeleteIcon} alt="" />
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "14px", color: "#990000" }}
                                  ml={1}
                                >
                                  Delete Ingredient
                                </Typography>
                              </Grid>
                            </Box>
                          )}

                        {/* )} */}
                        <IconButton
                          size="small"
                          onClick={() => handleMoreOption(ingredient)}
                          style={{ marginLeft: 5 }}
                        >
                          {openMoreOptions &&
                          ingredient.id === selectIngredientId ? (
                            <CloseIcon />
                          ) : (
                            <MoreVertIcon />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          <Grid item xs={12}>
            <Button
              onClick={() => {
                addRecipeIngredient(sectionId);
              }}
            >
              <Typography
                variant="body2"
                color="#EF4823"
                sx={{ textTransform: "none", fontSize: "14px", ml: 1 }}
              >
                Add new row
              </Typography>
            </Button>
          </Grid>
          <ReorderIngredientsModal
            filterIngredient={filterIngredient}
            openReorderPopup={openReorderPopup}
            setOpenReorderPopup={setOpenReorderPopup}
            sectionId={sectionId}
          />
        </Grid>
      )}
    </Box>
  );
}

const styles = {
  tableHeader: {
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    // background: "#f5f5f5",
    marginRight: "1px",
    // borderBottom: "1px solid black",
    borderRadius: "2px",
  },
  noBorder: {
    border: "none",
    outline: "none",
  },
};
