/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// ControlledAutoComplete is a component that allows the user to enter a list of options that they can choose from.
// It has an onChange prop that allows the user to change the value of the component, and a value prop that allows the user to set the value of the component.
// The component also has a getOptionLabel prop that allows the user to customize the label of the options that they can choose from.
import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import { matchSorter } from "match-sorter";

const ControlledAutoComplete = ({
  control,
  name,
  options = [],
  label,
  multiple = false,
  collectionLookupKey = "name",
  size = "small",
  onRecipesChange,
}) => {
  const { field } = useController({
    name,
    control,
    //   rules: { required: true },
  });

  const [existingRecipes, setExistingRecipes] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (name === "recipeList" && field.value) {
      setExistingRecipes(field.value);
    }
  }, []);

  const filterOptions = (options, { inputValue }) => {
    return inputValue === ""
      ? options
      : matchSorter(options, inputValue, {
          keys: [collectionLookupKey],
          threshold: matchSorter.rankings.CONTAINS,
        });
  };

  return (
    <Autocomplete
      size={size}
      disablePortal
      id={field.name}
      onChange={(evt, newValue) => {
        console.log("Option changed: ", newValue);
        field.onChange(newValue);
        if (name === "recipeList" && onRecipesChange) {
          onRecipesChange(newValue, existingRecipes);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      onBlur={field.onBlur}
      name={field.name}
      freeSolo={multiple ? undefined : false}
      options={options}
      value={field.value !== undefined ? field.value : multiple ? [] : null}
      multiple={multiple}
      getOptionLabel={(option) => {
        const label = typeof option === "string" ? option : option[collectionLookupKey] || "N/A";
        return label.charAt(0).toUpperCase() + label.slice(1);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <li {...props} style={{ textTransform: 'capitalize' }}>
          {typeof option === "string" ? option : option[collectionLookupKey] || "N/A"}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search or choose from the list"
          fullWidth
          label={label}
          inputRef={field.ref}
          sx={{
            ".MuiInputBase-input": {
              textTransform: "capitalize",
              fontFamily: "Mukta Mahee",
              fontSize: "16px",
            },
          }}
        />
      )}
      filterOptions={filterOptions}
    />
  );
};

ControlledAutoComplete.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  collectionLookupKey: PropTypes.string,
  size: PropTypes.string,
  onRecipesChange: PropTypes.func,
};

export default ControlledAutoComplete;