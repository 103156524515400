/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useEntity from "./hooks/use-entity";
import _ from "lodash";
import toast from "react-hot-toast";
import ErrorComponent from "./components/error-component";
import useRecipeConstants from "./hooks/use-recipe-constants";
import { FirebaseApi } from "../../config/firebase-config";
import { recipeStructureChangingFromNewToOld } from "./utils/recipe-structure-changing-from-new-to-old";
import { recipeStructureChangingFromOldToNew } from "./utils/recipe-structure-changing-from-old-to-new";
import { userLogsCollection } from "./constants";

const useRecipeHook = (props) => {
  const { generateDefaultValue, validationSchema, userLogs } =
    useRecipeConstants();

  // index.js
  const location = useLocation();
  const navigate = useNavigate();
  const { updateEntity, addEntity, entityByDifferentCollections } = useEntity();
  const [recipe, setRecipe] = useState(location.state ? location.state : null);
  const currentRecipe = JSON.parse(JSON.stringify(recipe));
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(false);
  const [isVerifiedByklynk, setIsVerifiedByklynk] = useState(false);
  const [checkingInstructionError, setCheckingInstructionError] =
    useState(false);
  const [checkedMetaDataError, setCheckedMetaDataError] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: recipe ? recipe : generateDefaultValue,
  });

  // generating id in the initial/new recipe starting

  const params = useParams();
  // firebase collections recipe needs to go
  const entityName = "testRecipes";
  const entityArrayName = [
    "recipeCards",
    "recipeIngredients",
    "recipeSections",
    "recipeInstructions",
    "cookingParameters",
  ];

  const generationOfNewUuid = () => {
    return uuidv4();
  };
  function findDifferences(obj1, obj2) {
    return _.reduce(
      obj1,
      (result, value, key) => {
        if (!_.isEqual(value, obj2[key])) {
          result[key] = { old: value, new: obj2[key] };
        }
        return result;
      },
      {}
    );
  }
  const addUserLogsHandler = async (data) => {
    try {
      userLogs["operationType"] = formState ? "edit" : "create";
      userLogs["newRecord"] = JSON.stringify(data);
      userLogs["diiferenceOfRecord"] = formState
        ? JSON.stringify(findDifferences(currentRecipe, data))
        : "";
      userLogs['recipeName'] = data.metadata.title;
      userLogs['recipeID'] = data.metadata.recipeId;

      await FirebaseApi[userLogsCollection].add(userLogs);
    } catch (error) {
      console.error("Error writing user log: ", error);
    }
  };

  const recipeIngredientsInArray = (ingredientList) => {
    let list = [];
    ingredientList?.length > 0 &&
      ingredientList.map(
        (item) => !list.includes(item.name) && list.push(item.name)
      );
    return {
      recipeIngredientNames: list,
      recipeIngredientsLength: list.length,
    };
  };

  const {
    formState: { isDirty, errors, isSubmitting },
    handleSubmit,
    watch,
    control,
    setValue: setFormValue,
    reset: resetFormValue,
  } = methods;

  const formValues = watch();

  const checkKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };
  const handleConfirmForVerified = (data) => {
    setIsVerifiedByklynk(false);
    submitRecipe(data);
  };

  const onSubmit = (data) => {
    if (formState) {
      submitRecipe(data);
    } else {
      setIsVerifiedByklynk(true);
    }
    setIsFormDirty(false);
  };

  const submitRecipe = async (data) => {
    await entityByDifferentCollections(
      entityArrayName,
      {
        ...data,
        ...recipeIngredientsInArray(data.recipeIngredients),
        restTimeInMins: data.metadata.restTimeInMins * 60,
      },
      formState,
      setIsLoading
    );
    await addUserLogsHandler(data);
  };

  const formErrors = methods?.formState?.errors;
  const metaDataError = formErrors?.metadata;

  useEffect(() => {
    let metaDataErrorKeys = metaDataError && Object.keys(metaDataError);
    let filteredKeys = metaDataErrorKeys?.filter(
      (i) => i !== "recipeServeDescription"
    );
    if (filteredKeys?.length > 0) {
      setCheckedMetaDataError(true);
    } else {
      setCheckedMetaDataError(false);
    }
  }, [metaDataError]);

  useEffect(() => {
    let errorKeys = formErrors && Object.keys(formErrors);
    let hasChecked =
      errorKeys &&
      (errorKeys.includes("recipeInstructions") ||
        errorKeys.includes("recipeIngredients") ||
        errorKeys.includes("recipeSections") ||
        errorKeys.includes("cookingParameters"));

    if (formErrors) {
      if (
        hasChecked ||
        metaDataError?.prepImage ||
        metaDataError?.recipeServeDescription
      ) {
        setCheckingInstructionError(true);
      } else {
        setCheckingInstructionError(false);
      }
    }
  }, [formErrors]);
  useEffect(() => {
    let errorKeys = formErrors && Object.keys(formErrors);
    if (formErrors) {
      if (
        errorKeys.includes("recipeInstructions") ||
        errorKeys.includes("recipeIngredients") ||
        errorKeys.includes("recipeSections") ||
        errorKeys.includes("cookingParameters")
      ) {
        setCheckingInstructionError(true);
      }
    }
  }, [formErrors]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0 && isFormSubmit) {
      setToastVisible(true);
    }
  }, [formErrors, isFormSubmit]);

  // useEffect(() => {
  //   if (toastVisible) {
  //     const timer = setTimeout(() => {
  //       setToastVisible(false);
  //     }, 5000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [toastVisible]);

  // useEffect(() => {
  //   if (toastVisible) {
  //     toast((t) => <ErrorComponent />, {
  //       duration: 5000,
  //     });
  //   }
  // }, [toastVisible]);

  const fetchAndSetItemsByField = async (
    entityArrayName,
    setData,
    recipeId
  ) => {
    setIsLoading(true);
    try {
      const result = {};

      for (const collectionName of entityArrayName) {
        if (collectionName === "recipeCards") {
          const doc = await FirebaseApi[collectionName].doc(recipeId).get();

          if (doc.exists) {
            result["metadata"] = {
              recipeId: doc.id,
              id: doc.id,
              ...doc.data(),
              restTimeInMins: doc.data().restTimeInMins / 60,
            };
          }
        } else {
          const data = await FirebaseApi[collectionName]
            .where("recipeId", "==", recipeId)
            .get();

          if (!data.empty) {
            result[collectionName] = [];
            data.forEach((doc) => {
              result[collectionName].push({ id: doc.id, ...doc.data() });
            });

            // Sort the array based on sectionIndex for recipeSection and instructionIndex for recipeInstruction
            if (collectionName === "recipeSections") {
              result[collectionName].sort(
                (a, b) => a.sectionIndex - b.sectionIndex
              );
            } else if (collectionName === "recipeInstructions") {
              result[collectionName].sort(
                (a, b) => a.instructionIndex - b.instructionIndex
              );
            } else if (collectionName === "cookingParameters") {
              result[collectionName].sort(
                (a, b) => a.instructionIndex - b.instructionIndex
              );
            }
          }
        }
      }
      setData(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    if (params.recipeId) {
      fetchAndSetItemsByField(
        entityArrayName,
        setRecipe,
        params.recipeId.split("&")[0]
      );
    }

    return () => {};
  }, [params.id]);

  useEffect(() => {
    if (recipe) {
      methods.reset(recipe);
      setFormState(true);
      setIsLoading(false);
    }
    return () => {};
  }, [recipe]);

  useEffect(() => {
    setIsFormDirty(methods?.formState?.isDirty);
  }, [methods?.formState?.isDirty]);

  return {
    // form controls
    methods,
    checkKeyDown,
    onSubmit,
    setIsLoading,
    handleConfirmForVerified,
    submitRecipe,

    // error states
    formErrors,
    toastVisible,
    setToastVisible,
    checkingInstructionError,
    setIsFormSubmit,

    // boolean triggers
    isFormDirty,
    formState,
    isLoading,
    isVerifiedByklynk,
    setIsVerifiedByklynk,
    checkedMetaDataError,

    control,
    handleSubmit,
    setFormValue,
    formValues,
    isDirty,
    resetFormValue,
    errors,

    // ingredient section
    recipeIngredientsInArray,
    generationOfNewUuid,
  };
};

export default useRecipeHook;
