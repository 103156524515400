import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

// Global styles object
const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    height: "80vh",
    bgcolor: "#f9f9f9",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    outline: "none",
  },
  contentBox: {
    overflowY: "auto",
    height: "90%",
    paddingRight: "16px",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    color: "error.main",
    marginBottom: "24px",
  },
  sectionTitle: {
    marginBottom: "8px",
    color: "primary.main",
    fontWeight: "bold",
  },
  listItem: {
    marginBottom: "16px",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  messageText: {
    color: "error.main",
  },
  closeButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
  },
  divider: {
    marginTop: "16px",
    marginBottom: "16px",
  },
};

export default function ErrorPopup(props) {
  const { toastVisible, setToastVisible, formErrors } = props;

  const {
    cookingParameters,
    metadata,
    recipeIngredients,
    recipeInstructions,
    recipeSections,
  } = formErrors || {};

  const renderMessages = (items, title) =>
    items &&
    items.length > 0 && (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={styles.sectionTitle}>
          {title}
        </Typography>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              {Object.entries(item).map(([key, value]) => (
                <Box key={key} sx={{ mb: 1 }}>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={styles.listItem}
                  >
                    {`In ${title} (${index + 1}) - ${key}: `}
                  </Typography>
                  <Typography
                    variant="body3"
                    component="span"
                    sx={styles.messageText}
                  >
                    {value?.message || "No message"}
                  </Typography>
                </Box>
              ))}
            </li>
          ))}
        </ul>
        <Divider sx={styles.divider} />
      </Box>
    );

  return (
    <div>
      <Button variant="contained" onClick={() => setToastVisible(true)}>
        Open Modal
      </Button>
      <Modal
        open={toastVisible}
        onClose={() => setToastVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <Box sx={styles.contentBox}>
            <Typography variant="h4" sx={styles.title}>
              List of Errors
            </Typography>

            {/* Metadata */}
            {metadata && (
              <>
                <Typography variant="h6" sx={styles.sectionTitle}>
                  Metadata
                </Typography>
                <ul>
                  {Object.entries(metadata).map(([key, value], index) => (
                    <li key={index}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={styles.listItem}
                      >
                        {key}:
                      </Typography>{" "}
                      <Typography
                        variant="body3"
                        component="span"
                        sx={styles.messageText}
                      >
                        {value?.message || JSON.stringify(value)}
                      </Typography>
                    </li>
                  ))}
                </ul>
                <Divider sx={styles.divider} />
              </>
            )}

            {renderMessages(cookingParameters, "Cooking Parameters")}
            {renderMessages(recipeIngredients, "Recipe Ingredients")}
            {renderMessages(recipeInstructions, "Recipe Instructions")}
            {renderMessages(recipeSections, "Recipe Sections")}
          </Box>

          <Box sx={styles.closeButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setToastVisible(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
